export function handleYouTubeError(error) {
    if (error.response) {
      const { status } = error.response;
      if (status === 403) {
        console.error('YouTube API quota exceeded');
        // You can add additional handling here
      } else if (status === 404) {
        console.error('YouTube resource not found');
      } else {
        console.error('YouTube API error:', error.response.data);
      }
    } else if (error.request) {
      console.error('No response received from YouTube API');
    } else {
      console.error('Error setting up YouTube request:', error.message);
    }
  }