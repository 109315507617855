import axios from 'axios';
import { parse, toSeconds } from 'iso8601-duration';
import { cache, getFromCache, addToCache } from '../utils/cache';
import { handleYouTubeError } from '../utils/errorHandling';
// ... rest of your existing code ...

const ITUNES_API_URL = 'https://itunes.apple.com/search';
const SPOTIFY_API_URL = 'https://api.spotify.com/v1/search';
const YOUTUBE_API_URL = 'https://www.googleapis.com/youtube/v3/search';

const SPOTIFY_CLIENT_ID = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
const SPOTIFY_CLIENT_SECRET = process.env.REACT_APP_SPOTIFY_CLIENT_SECRET;
const YOUTUBE_API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY;

let isYouTubeQuotaExceeded = false;


async function getSpotifyToken() {
  try {
    const response = await fetch('https://accounts.spotify.com/api/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic ' + btoa(SPOTIFY_CLIENT_ID + ':' + SPOTIFY_CLIENT_SECRET)
      },
      body: 'grant_type=client_credentials'
    });
    const data = await response.json();
    if (!data.access_token) {
      throw new Error('No access token received from Spotify');
    }
    return data.access_token;
  } catch (error) {
    console.error('Error getting Spotify token:', error);
    throw error;
  }
}

function filterByDuration(episodes, duration) {
  switch (duration) {
    case 'short':
      return episodes.filter(episode => episode.duration_ms < 240000); // Less than 4 minutes
    case 'medium':
      return episodes.filter(episode => episode.duration_ms >= 240000 && episode.duration_ms <= 1200000); // 4-20 minutes
    case 'long':
      return episodes.filter(episode => episode.duration_ms > 1200000); // More than 20 minutes
    default:
      return episodes;
  }
}

async function searchITunesPodcasts(query, language = 'en', duration = 'any') {
  const response = await fetch(`${ITUNES_API_URL}?term=${encodeURIComponent(query)}&entity=podcastEpisode&limit=50&language=${language}`);
  const data = await response.json();
  const episodes = data.results.map(episode => ({
    id: episode.trackId,
    title: episode.trackName,
    description: episode.description,
    showName: episode.collectionName || 'Unknown Show',
    showDescription: episode.collectionDescription || 'No description available',
    url: episode.trackViewUrl,
    releaseDate: new Date(episode.releaseDate).toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' }),
    language: episode.languageCodesISO2A || language,
    platform: 'iTunes',
    duration_ms: episode.trackTimeMillis,
    image: episode.artworkUrl600
  }));
  return filterByDuration(episodes, duration);
}

async function searchSpotifyPodcasts(query, language = 'en', duration = 'any') {
  try {
    const token = await getSpotifyToken();
    if (!token) {
      console.error('Failed to get Spotify token');
      return [];
    }
    
    const searchUrl = `${SPOTIFY_API_URL}?q=${encodeURIComponent(query)}&type=episode&market=US&limit=50&language=${language}`;
    
    const searchResponse = await fetch(searchUrl, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    
    if (!searchResponse.ok) {
      throw new Error(`Spotify API responded with status ${searchResponse.status}`);
    }
    
    const searchData = await searchResponse.json();
    
    if (!searchData.episodes || !Array.isArray(searchData.episodes.items)) {
      console.error('Unexpected Spotify API response structure:', searchData);
      return [];
    }
    
    const episodes = searchData.episodes.items.map((episode) => ({
      id: episode.id || 'Unknown ID',
      title: episode.name || 'Unknown Title',
      description: episode.description || 'No description available',
      showName: episode.show?.name || 'Unknown Show',
      showDescription: episode.show?.description || 'No show description available',
      url: episode.external_urls?.spotify || '#',
      releaseDate: new Date(episode.release_date).toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' }) || 'Unknown date',
      language: episode.language || language,
      platform: 'Spotify',
      image: episode.images?.[0]?.url || 'path/to/default/image.jpg',
      duration_ms: episode.duration_ms
    }));

    return filterByDuration(episodes, duration);
  } catch (error) {
    console.error('Error in searchSpotifyPodcasts:', error);
    return [];
  }
}

async function searchYouTubePodcasts(query, language = 'en', duration = 'any', pageToken = '') {
  if (isYouTubeQuotaExceeded) {
    return { items: [], nextPageToken: null, totalResults: 0 };
  }

  const cacheKey = `youtube:${query}:${language}:${duration}:${pageToken}`;
  const cachedResult = getFromCache(cacheKey);
  if (cachedResult) return cachedResult;

  try {
    // First API call to search for videos
    const searchResponse = await axios.get(YOUTUBE_API_URL, {
      params: {
        part: 'snippet',
        q: `${query} podcast`,
        type: 'video',
        maxResults: 50,
        key: YOUTUBE_API_KEY,
        relevanceLanguage: language,
        pageToken: pageToken,
        fields: 'items(id/videoId,snippet(title,description,channelTitle,publishedAt,thumbnails/high/url)),nextPageToken,pageInfo/totalResults'
      },
    });

    // Get video IDs for content details
    const videoIds = searchResponse.data.items.map(item => item.id.videoId).join(',');
    
    // Second API call to get video durations
    const contentDetailsResponse = await axios.get('https://www.googleapis.com/youtube/v3/videos', {
      params: {
        part: 'contentDetails',
        id: videoIds,
        key: YOUTUBE_API_KEY,
      },
    });

    // Create a map of video durations
    const contentDetailsMap = new Map(
      contentDetailsResponse.data.items.map(item => [
        item.id,
        item.contentDetails
      ])
    );

    // Process and filter items
    const items = searchResponse.data.items
      .map(item => {
        const contentDetails = contentDetailsMap.get(item.id.videoId);
        if (!contentDetails) return null;
        
        const durationSeconds = toSeconds(parse(contentDetails.duration));
        
        // Duration filtering logic
        if (duration === 'short' && durationSeconds >= 240) return null;           // < 4 minutes
        if (duration === 'medium' && (durationSeconds < 240 || durationSeconds > 1200)) return null;  // 4-20 minutes
        if (duration === 'long' && durationSeconds <= 1200) return null;          // > 20 minutes
        if (duration === 'any') {
          // No duration filtering needed
        }
        
        // ... existing code ...
        return {
          id: item.id.videoId,
          title: item.snippet.title,
          description: item.snippet.description,
          image: item.snippet.thumbnails.high.url,  // Changed from thumbnail to image
          url: `https://www.youtube.com/watch?v=${item.id.videoId}`,
          releaseDate: new Date(item.snippet.publishedAt).toLocaleDateString(),
          duration: duration === 'short' ? '< 4 min' : 
                   duration === 'medium' ? '4-20 min' : 
                   '> 20 min',
          platform: 'YouTube'
        };
      })
      .filter(Boolean); // Remove null items

    const result = { 
      items, 
      nextPageToken: searchResponse.data.nextPageToken, 
      totalResults: items.length 
    };
    
    addToCache(cacheKey, result);
    return result;

  } catch (error) {
    handleYouTubeError(error);
    return { items: [], nextPageToken: null, totalResults: 0 };
  }
}

function categorizeDuration(isoDuration) {
  const seconds = toSeconds(parse(isoDuration));
  if (seconds < 240) return 'Short (< 4 min)'; // Less than 4 minutes
  if (seconds <= 1200) return 'Medium (4-20 min)'; // 4-20 minutes
  return 'Long (> 20 min)'; // More than 20 minutes
}
function sortResults(results, sortBy) {
  if (sortBy === 'none') return results;

  return [...results].sort((a, b) => {
    // Date sorting
    if (sortBy.startsWith('date')) {
      const dateA = new Date(a.releaseDate || a.publishDate);
      const dateB = new Date(b.releaseDate || b.publishDate);
      return sortBy === 'date-new' ? dateB - dateA : dateA - dateB;
    }

    // Duration sorting
    if (sortBy.startsWith('duration')) {
      const getDurationValue = (item) => {
        if (item.duration_ms) return item.duration_ms;
        if (item.duration) {
          if (item.duration === '< 4 min') return 2 * 60 * 1000;
          if (item.duration === '4-20 min') return 12 * 60 * 1000;
          if (item.duration === '> 20 min') return 30 * 60 * 1000;
        }
        return 0;
      };
      const durationA = getDurationValue(a);
      const durationB = getDurationValue(b);
      return sortBy === 'duration-long' ? durationB - durationA : durationA - durationB;
    }
    return 0;
  });
}
async function searchPodcasts(query, platforms = ['iTunes', 'Spotify', 'YouTube'], language = 'en', duration = 'any', page = 1, youtubePageToken = '', sortBy = 'none') {
  const RESULTS_PER_PAGE = 20;
  const results = {};
  let allResults = [];

  try {
    await Promise.all(platforms.map(async (platform) => {
      switch (platform) {
        case 'iTunes':
          results.iTunes = await searchITunesPodcasts(query, language, duration);
          break;
        case 'Spotify':
          results.Spotify = await searchSpotifyPodcasts(query, language, duration);
          break;
        case 'YouTube':
          const youtubeResults = await searchYouTubePodcasts(query, language, duration, youtubePageToken);
          results.YouTube = youtubeResults.items;
          results.youtubeNextPageToken = youtubeResults.nextPageToken;
          break;
        default:
          break;
      }
    }));

    // Sort results for each platform
    Object.keys(results).forEach(platform => {
      if (Array.isArray(results[platform])) {
        results[platform] = sortResults(results[platform], sortBy);
      }
    });

    // Create a Set to track unique IDs
    const seenIds = new Set();

    // Alternate results from different platforms and remove duplicates
    const maxLength = Math.max(...Object.values(results)
      .filter(Array.isArray)
      .map(r => r.length));

    for (let i = 0; i < maxLength; i++) {
      for (const platform of platforms) {
        if (results[platform] && Array.isArray(results[platform]) && results[platform][i]) {
          const result = results[platform][i];
          const uniqueId = `${result.platform}-${result.id}`;
          
          if (!seenIds.has(uniqueId)) {
            seenIds.add(uniqueId);
            allResults.push(result);
          }
        }
      }
    }

    // Paginate results
    const startIndex = (page - 1) * RESULTS_PER_PAGE;
    const paginatedResults = allResults.slice(startIndex, startIndex + RESULTS_PER_PAGE);

    // Check if we actually have more results
    const hasMore = allResults.length > startIndex + RESULTS_PER_PAGE;

    return {
      results: paginatedResults,
      youtubeNextPageToken: hasMore ? results.youtubeNextPageToken : null,
      hasNextPage: hasMore,
      totalResults: allResults.length
    };

  } catch (error) {
    console.error('Error in searchPodcasts:', error);
    return { 
      results: [], 
      youtubeNextPageToken: null, 
      hasNextPage: false, 
      totalResults: 0 
    };
  }
}

async function getYouTubeVideoDetails(videoId) {
  const cacheKey = `youtube-details:${videoId}`;
  if (cache.has(cacheKey)) {
    return cache.get(cacheKey);
  }

  try {
    const response = await axios.get('https://www.googleapis.com/youtube/v3/videos', {
      params: {
        part: 'snippet',
        id: videoId,
        key: YOUTUBE_API_KEY,
      },
    });

    const videoDetails = response.data.items[0].snippet;
    cache.set(cacheKey, videoDetails);
    return videoDetails;
  } catch (error) {
    console.error('Error fetching YouTube video details:', error);
    return null;
  }
}

function checkYouTubeQuotaExceeded() {
  return isYouTubeQuotaExceeded;
}

function clearCache() {
  cache.clear();
}

// Set up daily cache clearing
setInterval(clearCache, 24 * 60 * 60 * 1000);

export { 
  searchPodcasts,
  checkYouTubeQuotaExceeded as isYouTubeQuotaExceeded, 
  getYouTubeVideoDetails,
  clearCache
};