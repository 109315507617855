const CACHE_MAX_SIZE = 1000;
const CACHE_ITEM_TTL = 1800000; // 30 minutes

const cache = new Map();

function getFromCache(key) {
  const item = cache.get(key);
  if (!item) return null;
  if (Date.now() - item.timestamp > CACHE_ITEM_TTL) {
    cache.delete(key);
    return null;
  }
  return item.value;
}

function addToCache(key, value) {
  if (cache.size >= CACHE_MAX_SIZE) {
    const oldestKey = cache.keys().next().value;
    cache.delete(oldestKey);
  }
  cache.set(key, {
    value,
    timestamp: Date.now()
  });
}

export { cache, getFromCache, addToCache };