import React, { useState, useCallback, useEffect} from 'react';
import { Button } from "./components/ui/button";
import { Input } from "./components/ui/input";
import { Card, CardHeader, CardTitle, CardContent } from "./components/ui/card";
import { Checkbox } from "./components/ui/checkbox";
import { Headphones, Star } from 'lucide-react';
import { searchPodcasts,isYouTubeQuotaExceeded, getYouTubeVideoDetails } from './api/podcastApi';
import PodcastDescriptionModal from './components/PodcastDescriptionModal';
import { 
  Select, 
  SelectContent, 
  SelectItem, 
  SelectTrigger, 
  SelectValue,
  SelectGroup,
  SelectLabel 
} from "./components/ui/select";

const PodcastExplorer = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [platforms, setPlatforms] = useState(['Spotify', 'YouTube']);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPodcast, setSelectedPodcast] = useState(null);
  const [duration, setDuration] = useState('long');
  const [youtubeNextPageToken, setYoutubeNextPageToken] = useState('');
  const [isYouTubeAvailable, setIsYouTubeAvailable] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [shouldSearch, setShouldSearch] = useState(false);
  const [fullDescription, setFullDescription] = useState('');
  const [starredPodcasts, setStarredPodcasts] = useState({});
  const [activeTab, setActiveTab] = useState('search');
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  // Add this with your other state declarations
  const [sortBy, setSortBy] = useState('none');
// Add this with your other constants at the top of the file
const RESULTS_PER_PAGE = 20;
// Add this with your other state declarations at the top of PodcastExplorer
const [youtubePageToken, setYoutubePageToken] = useState('');

const handleSearch = useCallback(async (isInitialSearch = true) => {
  if (!searchTerm.trim()) return;
  
  setIsLoading(true);
  setError(null);
  
  try {
    const { results, youtubeNextPageToken: newYoutubePageToken, hasNextPage } = 
      await searchPodcasts(
        searchTerm, 
        platforms, 
        'en', 
        duration, 
        currentPage,
        isInitialSearch ? '' : youtubePageToken,
        sortBy
      );
    
    setSearchResults(prevResults => isInitialSearch ? results : [...prevResults, ...results]);
    setYoutubePageToken(newYoutubePageToken);
    setHasNextPage(hasNextPage);
    
  } catch (error) {
    console.error('Error searching podcasts:', error);
    setError('An error occurred while searching for podcasts. Please try again.');
  } finally {
    setIsLoading(false);
  }
}, [searchTerm, platforms, duration, currentPage, youtubePageToken, sortBy, setSearchResults]);

const decodeHTMLEntities = (text) => {
  if (!text) return '';
  const textarea = document.createElement('textarea');
  textarea.innerHTML = text;
  return textarea.value;
};

useEffect(() => {
  const storedStarredPodcasts = localStorage.getItem('starredPodcasts');
  if (storedStarredPodcasts) {
    try {
      const parsed = JSON.parse(storedStarredPodcasts);
      setStarredPodcasts(parsed);
    } catch (error) {
      console.error('Error parsing stored podcasts:', error);
      localStorage.removeItem('starredPodcasts'); // Clear invalid data
    }
  }
}, []);
  

  useEffect(() => {
    if (shouldSearch) {
      const timeoutId = setTimeout(() => {
        handleSearch(true);
        setShouldSearch(false);
      }, 300);
      return () => clearTimeout(timeoutId);
    }
  }, [shouldSearch, handleSearch]);

  useEffect(() => {
    setIsYouTubeAvailable(!isYouTubeQuotaExceeded());
  }, []);

  const toggleStar = useCallback((podcast) => {
    setStarredPodcasts(prevStarred => {
      const podcastId = `${podcast.platform}-${podcast.id}`; // Create a unique ID
      const newStarred = { ...prevStarred };
      
      if (newStarred[podcastId]) {
        delete newStarred[podcastId];
      } else {
        newStarred[podcastId] = {
          ...podcast,
          uniqueId: podcastId // Store the unique ID
        };
      }
 // Save to localStorage
 try {
  localStorage.setItem('starredPodcasts', JSON.stringify(newStarred));
} catch (error) {
  console.error('Error saving to localStorage:', error);
}

return newStarred;
});
}, []);
  

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setShouldSearch(true);
  };
  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
    if (e.target.value.trim()) {
      setShouldSearch(true);
    }
  };
/*
  const handleDurationChange = async (newDuration) => {
    setDuration(newDuration);
    if (searchTerm) {
      setIsLoading(true);
      try {
        const results = await searchPodcasts(searchTerm, platforms, newDuration);
        setSearchResults(results);
        setCurrentPage(1);
        setHasNextPage(results.length === RESULTS_PER_PAGE);
      } catch (error) {
        setError('Failed to update search results');
      } finally {
        setIsLoading(false);
      }
    }
  };
  */
  const handleDurationChange = (newDuration) => {
    setDuration(newDuration);
    setCurrentPage(1);
    if (searchTerm) {
      setShouldSearch(true);
    }
  };


  const ContactModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-8 rounded-xl shadow-2xl max-w-md mx-4 border-t-4 border-purple-500">
          <h2 className="text-2xl font-bold mb-6 text-purple-600 flex items-center">
            <span role="img" aria-label="wave" className="mr-2">👋</span>
            Contact Us
          </h2>
          <p className="text-gray-700 mb-6 leading-relaxed">
            Questions/Comments/Feedback? Email{' '}
            <a 
              href="mailto:rankitbiz@gmail.com"
              className="text-purple-600 hover:text-purple-700 underline font-medium transition-colors duration-200"
            >
              rankitbiz@gmail.com
            </a>
            <br /><br />
            <span className="text-purple-500 font-medium">Thanks for using Find A Podcast!</span>
          </p>
          <Button 
            onClick={onClose}
            className="bg-purple-500 hover:bg-purple-600 text-white w-full rounded-lg py-3 transition-all duration-200 transform hover:scale-[1.02]"
          >
            Close
          </Button>
        </div>
      </div>
    );
  };

  const handlePlatformChange = (platform) => {
    const newPlatforms = platforms.includes(platform)
      ? platforms.filter(p => p !== platform)
      : [...platforms, platform];
    setPlatforms(newPlatforms);
    setCurrentPage(1);
    if (searchTerm) {
      setShouldSearch(true);
    }
  };

  const formatDuration = (durationMs) => {
    if (!durationMs || isNaN(durationMs)) return null;
    const totalMinutes = Math.floor(durationMs / 60000);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`;
  };
/*
  const handleSortChange = async (newSortBy) => {
    setSortBy(newSortBy);
    if (searchTerm) {
      setIsLoading(true);
      try {
        const results = await searchPodcasts(
          searchTerm,
          platforms,
          'en',
          duration,
          currentPage,
          youtubePageToken,
          newSortBy
        );
        setSearchResults(results.results);
        setHasNextPage(results.hasNextPage);
        setYoutubePageToken(results.youtubeNextPageToken);
      } catch (error) {
        setError('Failed to update search results');
      } finally {
        setIsLoading(false);
      }
    }
  };
  */
  const handleSortChange = (newSortBy) => {
    setSortBy(newSortBy);
    setCurrentPage(1);
    if (searchTerm) {
      setShouldSearch(true);
    }
  };
  
  // Update the Select component to use the new handler

  const handleSeeMore = async (podcast) => {
    setSelectedPodcast(podcast);
    if (podcast.platform === 'YouTube') {
      try {
        const details = await getYouTubeVideoDetails(podcast.id);
        setFullDescription(details.description);
      } catch (error) {
        console.error('Error fetching full YouTube description:', error);
        setFullDescription(podcast.description);
      }
    } else {
      setFullDescription(podcast.description);
    }
    setModalOpen(true);
  };

  const handleNextPage = () => {
    if (hasNextPage) {
      setCurrentPage(prevPage => prevPage + 1);
      handleSearch(false);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
      handleSearch(false);
    }
  };
  useEffect(() => {
    const toggleScrollLock = (lock) => {
      if (lock) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    };
  
    toggleScrollLock(modalOpen);
    
    // Cleanup function to ensure scroll is always enabled when component unmounts
    return () => toggleScrollLock(false);
  }, [modalOpen]);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    });
  };
  
// Update your renderPodcastCard function to use the decoder
const renderPodcastCard = (result, isStarred = false) => (
  <Card 
    key={`${result.platform}-${result.id}-${result.title}`} 
    className="overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300 flex flex-col"
  >
    <CardHeader className="bg-gradient-to-r from-purple-400 to-blue-400 text-white p-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center flex-1 mr-2">
          {result.image && (
            <img
              src={result.image}
              alt={decodeHTMLEntities(result.title)}
              className="w-16 h-16 rounded-md mr-4 object-cover"
            />
          )}
          <CardTitle className="text-lg font-bold leading-tight">
            {decodeHTMLEntities(result.title)}
          </CardTitle>
        </div>
        <Button
          onClick={() => toggleStar(result)}
          variant="ghost"
          size="icon"
          className="p-1 rounded-full hover:bg-white/20 transition-colors duration-200 bg-transparent"
          aria-label={isStarred ? "Unstar podcast" : "Star podcast"}
        >
          <Star
            className={`h-6 w-6 ${isStarred ? 'text-yellow-400 fill-current' : 'text-white stroke-current'}`}
            fill={isStarred ? 'currentColor' : 'none'}
            strokeWidth={2}
          />
        </Button>
      </div>
    </CardHeader>
    <CardContent className="p-4 flex-grow">
      <p className="text-sm mb-2 text-gray-600">
        {result.description && result.description.length > 100 
          ? <>
              {decodeHTMLEntities(result.description.slice(0, 100))}...
              <button 
                onClick={() => handleSeeMore(result)} 
                className="text-purple-500 hover:text-purple-600 ml-1 font-medium"
              >
                See more
              </button>
            </>
          : decodeHTMLEntities(result.description)
        }
      </p>
      <div className="text-xs text-gray-500 mt-2">
        <p>{formatDate(result.releaseDate)}</p>
        {result.duration && <p>Duration: {result.duration}</p>}
        {result.duration_ms && <p>Duration: {formatDuration(result.duration_ms)}</p>}
      </div>
    </CardContent>
    <div className="px-4 pb-4 mt-auto">
      <a 
        href={result.url} 
        target="_blank" 
        rel="noopener noreferrer" 
        className="block w-full text-center bg-purple-500 text-white py-2 px-4 rounded-full hover:bg-purple-600 transition-colors duration-200"
      >
        Listen on {result.platform}
      </a>
    </div>
  </Card>
);



return (
  <div className="flex flex-col min-h-screen bg-gradient-to-b from-purple-100 to-blue-100">
    <main className="flex-grow container mx-auto p-4">
      <h1 className="text-4xl font-bold mb-6 text-center text-purple-600 flex items-center justify-center">
        <Headphones className="mr-2" size={36} />
        FindAPodcast
      </h1>

     

      <div className="flex space-x-2 mb-4 bg-white p-2 rounded-lg shadow-sm">
  <Button
    onClick={() => setActiveTab('search')}
    variant={activeTab === 'search' ? 'default' : 'outline'}
    className={`flex-1 transition-all duration-200 ${
      activeTab === 'search' 
        ? 'bg-purple-500 text-white hover:bg-purple-600' 
        : 'bg-purple-50 text-purple-500 hover:bg-purple-100'
    }`}
  >
    Search
  </Button>
  <Button
    onClick={() => setActiveTab('starred')}
    variant={activeTab === 'starred' ? 'default' : 'outline'}
    className={`flex-1 transition-all duration-200 ${
      activeTab === 'starred' 
        ? 'bg-purple-500 text-white hover:bg-purple-600' 
        : 'bg-purple-50 text-purple-500 hover:bg-purple-100'
    }`}
  >
    Starred
  </Button>
</div>

      {activeTab === 'search' ? (
        <>
          <form onSubmit={handleSearchSubmit} className="mb-6">
  <div className="flex shadow-lg rounded-full overflow-hidden border-2 border-purple-300 hover:border-purple-400 transition-all duration-200">
    <Input
      type="text"
      name="search"
      placeholder="What topics interest you?"
      value={searchTerm}
      onChange={handleSearchTermChange}
      className="w-full py-3 px-6 border-none focus:ring-0 text-lg"
    />
    <Button 
      type="submit" 
      className="bg-purple-500 hover:bg-purple-600 text-white px-8 rounded-none"
    >
      {isLoading ? 'Searching...' : 'Search'}
    </Button>
  </div>
</form>
         

          <Card className="mb-8 shadow-lg">
  <CardContent className="p-6">
    <div className="flex flex-wrap gap-4 justify-center mb-4">
      {['iTunes', 'Spotify', 'YouTube'].map(platform => (
        (platform !== 'YouTube' || isYouTubeAvailable) && (
          <div key={platform} className="flex items-center space-x-2">
            <Checkbox
              id={platform.toLowerCase()}
              checked={platforms.includes(platform)}
              onCheckedChange={() => handlePlatformChange(platform)}
              className="w-6 h-6"
            />
            <label htmlFor={platform.toLowerCase()} className="text-sm font-medium cursor-pointer">{platform}</label>
          </div>
        )
      ))}
    </div>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <Select value={duration} onValueChange={handleDurationChange}>
        <SelectTrigger className="w-full">
          <SelectValue placeholder="Select duration" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="any">Any duration</SelectItem>
          <SelectItem value="short">Short (&lt; 4 min)</SelectItem>
          <SelectItem value="medium">Medium (4-20 min)</SelectItem>
          <SelectItem value="long">Long (&gt; 20 min)</SelectItem>
        </SelectContent>
      </Select>

      <Select value={sortBy} onValueChange={handleSortChange}>
  <SelectTrigger className="w-full">
    <SelectValue placeholder="Sort by" />
  </SelectTrigger>
  <SelectContent>
    <SelectItem value="none">Default</SelectItem>
    <SelectGroup>
      <SelectLabel>Date</SelectLabel>
      <SelectItem value="date-new">Newest First</SelectItem>
      <SelectItem value="date-old">Oldest First</SelectItem>
    </SelectGroup>
    <SelectGroup>
      <SelectLabel>Duration</SelectLabel>
      <SelectItem value="duration-long">Longest First</SelectItem>
      <SelectItem value="duration-short">Shortest First</SelectItem>
    </SelectGroup>
  </SelectContent>
</Select>
    </div>
  </CardContent>
</Card>

          {searchResults.length > 0 && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
             {searchResults.map((result) => {
  const podcastId = `${result.platform}-${result.id}`;
  return renderPodcastCard(result, !!starredPodcasts[podcastId]);
})}
            </div>
          )}

          {error && <p className="text-red-500 mb-4 text-center">{error}</p>}

          {(currentPage > 1 || hasNextPage) && (
            <div className="flex justify-center mt-6 space-x-4">
              {currentPage > 1 && (
                <Button 
                  onClick={handlePrevPage} 
                  className="bg-purple-500 hover:bg-purple-600 text-white"
                  disabled={isLoading}
                >
                  Previous
                </Button>
              )}
              {hasNextPage && (
                <Button 
                  onClick={handleNextPage} 
                  className="bg-purple-500 hover:bg-purple-600 text-white"
                  disabled={isLoading}
                >
                  Next
                </Button>
              )}
            </div>
          )}

          {isLoading && <p className="text-center mt-4">Loading more results...</p>}
          {!hasNextPage && searchResults.length > 0 && <p className="text-center mt-4">End of results</p>}
        </>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {Object.values(starredPodcasts).map(podcast => 
            renderPodcastCard(podcast, true)
          )}
        </div>
      )}

      <PodcastDescriptionModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        title={selectedPodcast ? decodeHTMLEntities(selectedPodcast.title) : ''}
       description={decodeHTMLEntities(fullDescription)}
      />
    </main>

    <footer className="bg-purple-600 text-white py-2 mt-8">
  <div className="container mx-auto px-4">
    <div className="flex flex-wrap justify-center items-center gap-2 text-sm">
      <span>&copy; 2024 FindAPodcast</span>
      <span className="hidden sm:inline">•</span>
      <Button 
        onClick={() => setIsContactModalOpen(true)}
        variant="ghost" 
        className="text-white hover:bg-white/20 p-2 h-auto"
        size="sm"
      >
        Contact Me
      </Button>
    </div>
  </div>
  <ContactModal 
    isOpen={isContactModalOpen} 
    onClose={() => setIsContactModalOpen(false)} 
  />
</footer>
  </div>
);

}

export default PodcastExplorer;